.ended_page {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;

  align-items: center;
  overflow: scroll;
  padding-bottom: 94px;
}
.ended_content {
  background: linear-gradient(#ffffff 0%, #f1f1f1 20%);
}
.ended_nav {
  padding: 0px 20px 0px 20px;
}
.detail_activity_chats_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: linear-gradient(#ffffff 0%, #f1f1f1 20%);
}
