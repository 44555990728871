.activity-card {
  height: 60px;
  display: flex;
  background-color: #ffffff;
  border-radius: 28px;
  padding: 20px;
  gap: 20px;
}
.chat-card__content {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.timeCircle {
  width: 48px;
  height: 48px;
  padding-bottom: 4px;
}
.session-duration {
  font-family: "Satoshi";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-align: left;
  color: rgba(97, 97, 97, 1);
}
