.chat_history_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: scroll;
  background: linear-gradient(#ffffff 0%, #f1f1f1 20%);
  padding-bottom: 94px;
}
.active {
  padding-top: 20px;
  width: 100%;
}
.ended {
  width: 100%;
}
.active_text {
  font-weight: 700;
  font-family: "Satoshi";
  size: 20px;
  line-height: 24px;
  padding: 0 24px 24px 24px;
}
.ended_line {
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px 24px;
}
.ended_text {
  font-weight: 700;
  font-family: "Satoshi";
  size: 20px;
  line-height: 24px;
}
.view_all {
  color: rgba(105, 73, 255, 1);
  font-weight: 500;
  size: 16px;
  font-family: "Satoshi";
  line-height: 22.4px;
  align-self: right;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.start_another_button {
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  font-family: "Urbanist";
  width: fill-available;
  height: 58px;
  background-color: #6949ff; /* Purple color */
  color: white;
  gap: 10px;
  border: #6949ff;
  padding: 18px 16px 18px 16px;
  border-radius: 100px;
  box-shadow: 4px 8px 24px 0 rgba(0, 205, 189, 0.25);
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 36px;
  position: fixed;
  bottom: 0;
  z-index: 99;
}
