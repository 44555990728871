@font-face {
  font-family: "Urbanist";
  src: url("../public/assets/fonts/Urbanist-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Satoshi";
  src: url("../public/assets/fonts/Satoshi-Variable.ttf");
}

body {
  font-family: "Urbanist";
  margin: 0 !important;
}
