.message-owner {
  display: flex;
  justify-content: flex-end;
  .messageContent {
    max-width: 71%;
    background-color: #6948ff;
    border-radius: 20px 20px 8px 20px;
    padding: 16px 24px;
    margin: 10px 0;
    word-wrap: break-word;
    color: white;
    box-sizing: border-box;
    .messageText {
      overflow-wrap: break-word;
      margin: 0;
    }
  }
}
.message-bot {
  display: flex;
  justify-content: flex-start;
  .messageContent {
    max-width: 71%;
    background-color: #fff;
    border-radius: 8px 20px 20px 20px;
    padding: 16px 24px;
    margin: 10px 0;
    word-wrap: break-word;
    color: rgba(33, 33, 33, 1);
    box-sizing: border-box;
    .messageText {
      overflow-wrap: break-word;
      margin: 0;
    }
  }
}

.messageContent {
  max-width: 71%;
  background-color: #6948ff;
  border-radius: 20px 20px 8px 20px;
  padding: 16px 24px;
  margin: 10px 0;
  word-wrap: break-word;
  color: white;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.messageText {
  overflow-wrap: break-word;
  margin: 0;
}
.messageContent p {
  overflow-wrap: break-word;
  margin: 0;
}
.messageText code,
.messageText pre {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}
