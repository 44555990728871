.dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 133px;
  height: 128px;
  border-radius: 16px;
  position: absolute;
  padding: 20px 28px 28px 20px;
  top: 28px;
  right: 0px;
  z-index: 1;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  font-weight: 600;
  size: 16px;
  line-height: 22.4px;
}
.dropdown-item-clear {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  height: 24px;
  width: 125px;
}
.dropdown-item-export {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  height: 24px;
  width: 125px;
}
.dropdown-item-end {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  height: 24px;
  width: 125px;
}
.divider-1 {
  border-top: 1px solid rgba(238, 238, 238, 1);
  width: 125px;
  height: 0px;
}
.divider-2 {
  border-top: 1px solid rgba(238, 238, 238, 1);
  width: 125px;
  height: 0px;
}
