.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome_img {
  width: 95%;
}
.page_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 28px 0;
}

.start_button {
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  font-family: Urbanist;
  width: 100%;
  height: 58px;
  background-color: #6949ff; /* Purple color */
  color: white;
  gap: 10px;
  border: #6949ff;
  padding: 18px 16px 18px 16px;
  border-radius: 100px;
  box-shadow: 4px 8px 24px 0 rgba(0, 205, 189, 0.25);
  margin-top: 36px;
}
.welcome__text {
  font-size: 32px;
  line-height: 38.4px;
  margin-top: 20px;
  font-weight: 700;
  font-family: Urbanist;
}
.career_help {
  font-size: 24px;
  line-height: 28.8px;
  margin-top: 48px;
  font-weight: 700;
  font-family: Urbanist;
  text-align: center;
}
.career_help_comment {
  width: fit-content;
  font-size: 16px;
  line-height: 22.4px;
  margin-top: 20px;
  font-weight: 500;
  font-family: "Satoshi";
}
