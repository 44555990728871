.sendboxContainer {
  display: flex;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.messageInput {
  line-height: 19.6px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Satoshi";
  height: 20px;
  background-color: #fafafa;
  border: none;
  flex-grow: 1;
  padding: 16px;
  border-radius: 12px;
  margin-right: 10px;
}

.sendButton {
  height: 56px;
  width: 56px;
  border: none;
  border-radius: 100%;
  background: linear-gradient(286.17deg, #6949ff 100%, #876dff 100%);
}

.sendIcon {
  padding-top: 4px;
  width: 20px;
  height: 20px;
}
