.chat-card-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 32px;
  margin: 0 24px 24px 24px;
  overflow: hidden;
  position: relative;
}
.chat-card-container-swipe {
  display: flex;
  background: rgba(247, 85, 85, 1);

  border-radius: 32px;
  margin: 0 24px 24px 24px;
  overflow: hidden;
  position: relative;
}
.chat-card {
  position: relative;
  background-color: #ffffff;
  gap: 20px;
  padding: 24px;
  display: flex;
  align-items: center;
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
  width: 100%;
  transition: transform 0.3s;
  overflow: hidden;
}
.conversation_title {
  font-family: "Satoshi";
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: rgba(33, 33, 33, 1);
}
.conversation_content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* 限制在三行 */
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示为省略号 */
  font-family: "Satoshi";
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: rgba(97, 97, 97, 1);
}
.icon-container {
  margin-left: auto;
}
.delete-area {
  position: absolute;
  right: 24px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
