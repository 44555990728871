.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  height: 48px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  margin-right: 8px;
}

.navbar-icons {
  display: flex;
  align-items: center;
  gap: 12px;
}
.navbar-icons button {
  background: none;
  border: none;
  cursor: pointer;
}

.rex-logo-text {
  color: #212121;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
