.history-session-time {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.detail-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.activity-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: linear-gradient(#ffffff 0%, #f1f1f1 20%);
}
.detail-chat-activity-title,
.timeOption-title {
  color: rgba(33, 33, 33, 1);
  font-family: "Satoshi";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  padding-top: 8px;
}
.chart-part {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.detail-chat-activity-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.timeOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statDescribe {
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-align: left;
  color: rgba(66, 66, 66, 1);
}
.see_all {
  padding-top: 10px;
  color: rgba(105, 73, 255, 1);
  font-weight: 500;
  size: 16px;
  font-family: "Satoshi";
  line-height: 22.4px;
  align-self: right;
  letter-spacing: 0.2px;
  cursor: pointer;
}
