.chat_page {
  padding: 24px 24px 24px 24px;
  height: 100vh;
  background: linear-gradient(#ffffff 0%, #f1f1f1 20%);
}
.chat-icon {
  padding-left: 22px;
}
.sendwrap {
  padding-top: 20px;
}
