.stat-container {
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  gap: 12px;
  display: flex;
  align-items: center;
  position: relative;
}
.activity_title {
  flex-grow: 1;
  font-size: 24px;
  font-weight: 700;
  font-family: "Urbanist";
  line-height: 28.8px;
  margin-left: 16px;
}
