.dropdown_container {
  display: flex;
  font-family: "Satoshi";
  font-size: 14px;
  line-height: 19.6px;
  padding: 6px 16px;
  border: 2px solid rgba(105, 73, 255, 1);
  border-radius: 100px;
  background-color: white;
  color: rgba(105, 73, 255, 1);
  outline: none;
}

.dropdown {
  display: flex;

  cursor: pointer;

  justify-content: space-between;
}
