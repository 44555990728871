.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 高于页面内容的 z-index 值 */
}
.modal-content {
  position: relative;
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 44px;
  padding: 48px 32px 40px 32px;
  gap: 32px;

  z-index: 1001;
}
.modal-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.modal-body-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
}
.modal-body-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  text-align: center;
}
.modal-body {
  gap: 16px;
}

.confirm-btn {
  width: 100%;
  height: 58px;
  border-radius: 100px;
  padding: 18px 16px 18px 16px;
  background: rgba(105, 73, 255, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #fff;
  border: none;
}
.cancel-btn {
  width: 100%;
  height: 58px;
  border-radius: 100px;
  padding: 18px 16px 18px 16px;
  background: rgba(240, 237, 255, 1);

  font-weight: 700;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: 0.2px;
  text-align: center;
  color: rgba(105, 73, 255, 1);
  border: none;
}
